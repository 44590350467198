import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { HiArrowLongDown } from "react-icons/hi2";
import { RiGithubFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import SlideTransition from "../SlideTransition";

import hero_fallback_bg from "../../assets/background/hero_fallback_bg.png";
import hero_video from "../../assets/videos/hero_video.mp4";
import hero_video_low_res from "../../assets/videos/hero_video_low_res.mp4";

import useInView from "../../hooks/useInView";
import useContent from "../../hooks/useContent";
import { MAIN_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const Hero = () => {
  const content = useContent();
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const containerRef = useRef(null);

  const [mouseOver, setMouseOver] = useState(false);

  const handleOnClickRequest = () => {
    document
      .querySelector(`#${MAIN_ROUTES.get("OVERVIEW").ID}`)
      ?.scrollIntoView({
        behavior: "smooth",
      });
  };

  return (
    <Box id={MAIN_ROUTES.get("HOME").ID} ref={setRef} sx={styles.heroContainer}>
      <Container maxWidth="lg" ref={containerRef} sx={styles.heroForeground}>
        <Box sx={styles.heroBody}>
          <Stack spacing={11} sx={styles.heroContent}>
            <Stack spacing={7} sx={styles.heroHeaderContainer}>
              <Stack spacing={1}>
                <SlideTransition appear={true} timeout={500}>
                  <Typography sx={styles.heroSubheader}>
                    {content.hero.subheader}
                  </Typography>
                </SlideTransition>

                <SlideTransition appear={true} timeout={600}>
                  <Typography sx={styles.heroHeader}>
                    {content.hero.header}
                  </Typography>
                </SlideTransition>
              </Stack>

              <SlideTransition appear={true} timeout={700}>
                <Typography sx={styles.heroText}>
                  {content.hero.content}
                </Typography>
              </SlideTransition>
            </Stack>

            <SlideTransition appear={true} timeout={800}>
              <Box sx={styles.heroAction}>
                <Button
                  onClick={handleOnClickRequest}
                  onMouseEnter={() => setMouseOver(true)}
                  onMouseLeave={() => setMouseOver(false)}
                  sx={styles.button}
                  variant="contained"
                >
                  {content.hero.action}

                  <Box
                    sx={{ display: "flex", position: "relative", width: 16 }}
                  >
                    <HiArrowLongDown
                      size={"1.2em"}
                      style={{
                        position: "absolute",
                        transform: mouseOver
                          ? "translateY(-9px)"
                          : "translateY(-42px)",
                        transition: "all 0.3s ease-in-out",
                      }}
                    />
                    <HiArrowLongDown
                      size={"1.2em"}
                      style={{
                        position: "absolute",
                        transform: mouseOver
                          ? "translateY(42px)"
                          : "translateY(-9px)",
                        transition: "all 0.3s ease-in-out",
                      }}
                    />
                  </Box>
                </Button>

                <Link
                  rel={"noopener noreferrer"}
                  target={"_blank"}
                  to={MAIN_ROUTES.get("GITHUB").PATH}
                >
                  <Button
                    color="primary"
                    sx={styles.buttonSecondary}
                    variant="contained"
                  >
                    <RiGithubFill size={"1.8em"} /> {content.hero.subaction}
                  </Button>
                </Link>
              </Box>
            </SlideTransition>
          </Stack>
        </Box>
      </Container>

      <Box sx={styles.videoContainer}>
        <Box sx={styles.videoPosition}>
          <video
            autoPlay
            id="_hero_video"
            loading="lazy"
            loop
            muted
            poster={hero_fallback_bg}
            preload="none"
            style={styles.video}
          >
            <source
              src={matches ? hero_video_low_res : hero_video}
              type="video/mp4"
            />
          </video>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
