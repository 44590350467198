import { AppBar, Box, Container, Slide, Toolbar } from "@mui/material";
import { useLayoutEffect, useState } from "react";

import Logo from "../../../components/Logo";
import NavList from "../NavList";
import NavMenu from "../NavMenu";

import useScrollPosition from "../../../hooks/useScrollPosition";

import { styles } from "./style";
import { theme } from "../../../utils/theme";

const Nav = () => {
  const { ref: rootRef, scrollPosition: posY } = useScrollPosition();
  const [windowHeight, setWindowHeight] = useState(null);

  useLayoutEffect(() => {
    setWindowHeight(window.innerHeight * 0.4);
  }, []);

  return (
    <Box ref={rootRef}>
      {/* <Slide in={posY > windowHeight * 1.2} timeout={{ enter: 800, exit: 400 }}>
        <AppBar elevation={0} sx={styles.navContained}>
          <Container maxWidth="lg">
            <Toolbar sx={styles.toolbar}>
              <Logo />

              <NavList />

              <NavMenu />
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>

      <Slide in={posY < 1} out={posY > 1} timeout={{ enter: 800, exit: 400 }}> */}
      <AppBar elevation={0} sx={styles.navDefault}>
        <Container maxWidth="xl">
          <Toolbar sx={styles.toolbar}>
            <Logo />

            <NavList />

            <NavMenu />
          </Toolbar>
        </Container>
      </AppBar>
      {/* </Slide> */}
    </Box>
  );
};

export default Nav;
